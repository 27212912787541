import React from 'react';
import { graphql, Link } from 'gatsby';


export default ({ value, rel }) => (
    <Link className="tag" to={value.slug} rel={rel ? 'tag' : null}>{value.name}</Link>
);

export const query = graphql`
    fragment Tag on TagField { name slug }
`;
