import React from 'react';
import { navigate } from 'gatsby';
import classnames from 'classnames';


// Simple div with innerHTML, but tracking ids and generating waypoint events
export default class Content extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onScrollAnimation = this.onScrollAnimation.bind(this);
    }

    render () {
        return (<div className={classnames(this.props.className, 'content')}
                     ref={c => (this._element = c)}
                     dangerouslySetInnerHTML={{ __html: this.props.html }}/>);
    }

    componentDidMount() {
        this.rebuildTargetList();
        this._element.addEventListener('click', this.onClick);
        window.addEventListener('scroll', this.onScroll);
    }

    componentDidUpdate() {
        this.rebuildTargetList();
    }

    componentWillUnmount() {
        this._element.removeEventListener('click', this.onClick);
        window.removeEventListener('scroll', this.onScroll);
        window.cancelAnimationFrame(this._frameId);
        this._frameId = null;
    }

    onClick(event) {
        const el = event.target;
        if (el.tagName === 'A' &&
            el.protocol === window.location.protocol &&
            el.host === window.location.host &&
            el.pathname === window.location.pathname) {

            event.preventDefault();
            const targetEl = document.getElementById(el.hash.substring(1));
            const style = window.getComputedStyle(targetEl);
            const marginTop = parseInt(style['margin-top'], 10);
            const rect = targetEl.getBoundingClientRect();

//             navigate(el.hash);
            window.scrollTo({
                top: rect.top + (window.scrollY || document.documentElement.scrollTop) - marginTop,
                behavior: 'smooth'
            });
        }
    }

    onScroll(event) {
        if (!this._frameId) {
            this._frameId = window.requestAnimationFrame(this.onScrollAnimation);
        }
    }

    onScrollAnimation() {
        this._frameId = null;
        const refPoint = window.innerHeight*.382;

        const targets = this._targets;
        let i = 0;
        for (i = 0; i < targets.length; ++i) {
            const target = targets[i];
            const rect = target.getBoundingClientRect();
            if (rect.top > refPoint) { break; }
        }

        const newTarget = i > 0 ? targets[i-1] : null;
        if (newTarget !== this._oldTarget) {
            if (this.props.onWaypoint) {
                this.props.onWaypoint(newTarget ? newTarget.id : null);
            }
            this._oldTarget = newTarget;
        }
    }

    rebuildTargetList() {
        this._targets = Array.prototype.slice.call(this._element.querySelectorAll('h1,h2,h3,h4,h5,h6'));
    }
}
