import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';

import AnchorLink from '../components/AnchorLink';
import Bio from '../components/Bio';
import Content from '../components/Content';
import PostExcerpt from '../components/PostExcerpt';


export default class SeriesPage extends React.Component {
    render() {
        const siteMetadata = this.props.data.site.siteMetadata;
        const { series, posts, seriesList } = this.props.data;
        const fullUrl = (siteMetadata.siteUrl + series.fields.slug).replace(/([^:])\/\//g, '$1/');
        const name = series.fullName || series.name;
        const title = name.replace(/\b(\w)/g, c => c.toUpperCase()) + ' Series';
        const description = series.description.replace(/ *\n+ */g, ' ').trim();

        return (
            <>
                <Helmet title={`${title} | ${siteMetadata.title}`}>
                    <html lang="en"/>
                    <link rel="canonical" href={fullUrl} />
                    <meta name="author" value={siteMetadata.author.fullName} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title} />
                    <meta property="og:site_name" content={siteMetadata.title} />
                    <meta property="og:description" content={description} />
                    <meta property="twitter:site:id" content={siteMetadata.twitterId} />
                    <link rel="alternate" type="application/json+oembed" href="/oembed.json" />
                </Helmet>
                <main className="series-page">
                    <h1>{title}</h1>
                    <Content className="description" html={series.description}/>
                    <section className="post-list">
                        {posts.map(post => (
                            <PostExcerpt key={post.fields.slug} post={post} />
                        ))}
                    </section>
                    <AnchorLink className="back-to-top" href="#">back to top</AnchorLink>
                </main>
                <aside>
                    <Bio person={siteMetadata.author} />
                    <div className="sticky">
                        <section className="series-list">
                            <h1>All series</h1>
                            <ul>{seriesList.edges.map(({ series }) => (
                                <li key={series.fields.slug} className="series-item">
                                    <Link to={series.fields.slug} activeClassName="active">{series.fullName || series.name}</Link>
                                </li>
                            ))}</ul>
                        </section>
                    </div>
                </aside>
            </>
        )
    }
}

export const pageQuery = graphql`
  query SeriesBySlug($slug: String!) {
    site {
        siteMetadata {
            title
            siteUrl
            twitterId
            author { ...BioPerson }
        }
    }
    series: seriesYaml(fields: { slug: { eq: $slug } }) {
        name
        fullName
        description
        fields { slug }
    }
    posts: allSeriesMarkdownRemark(filter: { fields: { slug: { eq: $slug } } },
                             sort: { fields: [frontmatter___date], order: DESC }) {
        ...PostExcerpt
    }
    seriesList: allSeriesYaml(sort: { fields: [name] }) {
        edges {
            series: node { name fullName fields { slug } }
        }
    }
  }
`
