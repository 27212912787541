import React from 'react';
import { navigate } from 'gatsby';


class AnchorLink extends React.PureComponent {
    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
    }

    render() {
        const { forwardedRef, ...rest } = this.props;
        return (
            <a ref={forwardedRef} {...rest} onClick={this.onClick} />
        )
    }

    onClick(event) {
        event.preventDefault();

        const href = this.props.href;
        if (!href) { return; }

        let to = 0;
        if (href !== '#') {
            const targetEl = document.getElementById(href.substring(1));
            if (!targetEl) { return; }

            const style = window.getComputedStyle(targetEl);
            const marginTop = parseInt(style['margin-top'], 10);
            const rect = targetEl.getBoundingClientRect();

            to = rect.top + (window.scrollY || document.documentElement.scrollTop) - marginTop;
        }

        event.target.blur();
//         navigate(href);
        window.scrollTo({top: to, behavior: 'smooth'});

        if (this.props.onClick) { this.props.onClick(event); }
    }
}

export default React.forwardRef((props, ref) => (
    <AnchorLink forwardedRef={ref} {...props} />
));
