import React from 'react';
import { graphql, Link } from 'gatsby';
import slugify from '../lib/slugify';

import Tag from './Tag';


export default class PostExcerpt extends React.PureComponent {
    render() {
        const post = this.props.post;
        const title = post.frontmatter.title || post.fields.slug;
        return (
            <article className="post-excerpt" id={slugify(title)}>
                <h2><Link to={post.fields.slug}>{title}</Link></h2>
                <div className="metadata">
                    <time dateTime={post.frontmatter.date}>{post.frontmatter.formattedDate}</time>
                    <ul className="tags">{post.tags.map((tag) => (
                        <li key={tag.slug}>
                            <Tag value={tag} />
                        </li>
                    ))}</ul>
                </div>
                <div className="excerpt">
                    <span dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                    <Link className="more" to={post.fields.slug}>▸ read more</Link>
                </div>
            </article>
        );
    }
}

export const query = graphql`
    fragment PostExcerpt on MarkdownRemark {
        excerpt
        fields { slug }
        frontmatter {
            title
            date
            formattedDate: date(formatString: "DD MMMM, YYYY")
        }
        tags: children { ...Tag }
    }
`;
